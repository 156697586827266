import { Button, Menu, Select } from 'antd';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ArrowDownOutlined, SyncOutlined } from '@ant-design/icons';

function DicomHeader({ tools, config, onSelectChange }) {
  const onReset = useCallback(() => {
    if (config.dwvApp) {
      config.dwvApp.resetDisplay();
    }
  }, [config]);

  const toolsMenuItems = Object.keys(tools).map((tool) => (
    <Select.Option key={tool} value={tool}>
      {tool}
    </Select.Option>
  ));

  const { selectedTool, dataLoaded } = config;
  return (
    <div className="dicomViewer__header">
      <Select
        disabled={!dataLoaded}
        style={{ width: 150 }}
        value={selectedTool}
        onChange={onSelectChange}
      >
        {toolsMenuItems}
      </Select>

      <Button
        type="primary"
        style={{ marginLeft: 16 }}
        disabled={!dataLoaded}
        onClick={onReset}
        icon={<SyncOutlined />}
      >
        Reset
      </Button>
    </div>
  );
}

DicomHeader.propTypes = {
  tools: PropTypes.objectOf(PropTypes.any).isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

export default DicomHeader;
