import { Button, Progress } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Flex from '../../components/Flex';
import DicomViewer from '../../components/viewer/dicomViewer';
import { fetchFile } from '../../services/request';
import './viewer.css';

const styles = {
  back: { marginBottom: 10 },
  flex: { overflow: 'auto', justifyContent: 'flex-start', transform: 'rotate(180deg)' },
  image: { height: 'calc(100vh - 200px)', transform: 'rotate(180deg)' },
};

function Viewer() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const ref = useRef();
  const [[files, isFetching], setFiles] = useState([[], true]);
  const [loaded, setLoaded] = useState(0);

  const url = state?.url;
  const length = state?.length;

  const fetchFiles = useCallback(async () => {
    try {
      const data = await fetchFile(url)
        .then((res) => {
          const reader = res.body.getReader();
          return new ReadableStream({
            start(controller) {
              function pump() {
                return reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  setLoaded((prev) => prev + value.length);
                  controller.enqueue(value);
                  pump();
                });
              }
              return pump();
            },
          });
        })
        .then((stream) => new Response(stream))
        .then(async (response) => {
          const json = await response.json().catch(() => null);
          if (!response.ok) throw json;
          return json;
        });
      if (Array.isArray(data)) setFiles([data, false]);
      else setFiles([[data], false]);
    } catch {
      setFiles([[], false]);
    }
  }, [url]);

  useEffect(() => {
    if (url) fetchFiles();
  }, [fetchFiles, url]);

  useEffect(() => {
    if (ref.current) ref.current.scrollLeft = ref.current.scrollWidth;
  }, [files]);

  if (isFetching)
    return (
      <Flex style={{ minHeight: '90vh' }}>
        <Progress type="circle" percent={Math.round((loaded / length) * 100)} />
      </Flex>
    );

  return (
    <>
      <Title>{t('FRONT_VIEWER_LABEL')}</Title>
      <Button style={styles.back} type="primary" onClick={() => history.goBack()}>
        {t('FRONT_COMMON_BACK')}
      </Button>
      <Flex forwardedRef={ref} className="viewer" style={styles.flex}>
        {files.map((m, i) => {
          if (m.mime.includes('image'))
            return (
              <img
                style={styles.image}
                key={Math.random()}
                alt={`file-${i}`}
                src={`data:${m.mime};base64, ${m.file}`}
              />
            );
          if (m.mime.includes('pdf'))
            return (
              <embed
                style={{ ...styles.image, width: '100%', minWidth: 500 }}
                key={Math.random()}
                src={`data:${m.mime};base64, ${m.file}`}
              />
            );
          if (m.mime.includes('zip') || m.mime.includes('dcm') || m.mime.includes('dicom'))
            return <DicomViewer key={Math.random()} m={m} index={i} />;
          return null;
        })}
      </Flex>
    </>
  );
}

export default Viewer;
