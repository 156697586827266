/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchAskDocuments } from '../redux/askDocuments/actions';
import { useAskDocuments } from '../redux/askDocuments/selectors';
import TableCustom from './Table';

function AskDocumentsTable({ reference }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [askDocuments, isFetching] = useAskDocuments();

  const fetchData = useCallback((page) => dispatch(fetchAskDocuments(reference, page)), [
    reference,
    dispatch,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_DOCUMENT_TYPE'), dataIndex: 'filetype' },
    { title: t('FRONT_DOCUMENT_DESCRIPTION'), dataIndex: 'description' },
  ];

  return (
    <TableCustom
      state={askDocuments}
      columns={columns}
      isFetching={isFetching}
      fetchData={fetchData}
    />
  );
}
export default AskDocumentsTable;
