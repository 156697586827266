/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dwv from 'dwv';
import { Progress } from 'antd';

import DicomHeader from './DicomHeader';

dwv.gui.getElement = dwv.gui.base.getElement;

dwv.gui.prompt = function (message, def) {
  return prompt(message, def);
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function DicomViewer({ m, index }) {
  const { file, mime } = m;
  const [config, setConfig] = useState({
    selectedTool: 'Select Tool',
    loadProgress: 0,
    dataLoaded: false,
    dwvApp: null,
    metaData: [],
  });

  const [tools] = useState({
    Scroll: {},
    ZoomAndPan: {},
    WindowLevel: {},
    Draw: {
      options: ['Ruler'],
      type: 'factory',
      events: ['draw-create', 'draw-change', 'draw-move', 'draw-delete'],
    },
  });

  const onChangeShape = useCallback(
    (shape) => {
      if (config.dwvApp) {
        config.dwvApp.setDrawShape(shape);
      }
    },
    [config.dwvApp]
  );

  const onChangeTool = useCallback(
    (tool) => {
      if (config.dwvApp) {
        setConfig((state) => ({ ...state, selectedTool: tool }));
        config.dwvApp.setTool(tool);
        if (tool === 'Draw') {
          onChangeShape(tools.Draw.options[0]);
        }
      }
    },
    [config.dwvApp, onChangeShape, tools.Draw.options]
  );

  useEffect(() => {
    const app = new dwv.App();
    app.init({
      containerDivId: `dwv-${index}`,
      tools,
    });

    let nLoadItem = null;
    let nReceivedError = null;
    let nReceivedAbort = null;

    app.addEventListener('loadstart', () => {
      nLoadItem = 0;
      nReceivedError = 0;
      nReceivedAbort = 0;
    });

    app.addEventListener('loadprogress', (event) => {
      setConfig((state) => ({ ...state, loadProgress: event.loaded }));
    });

    app.addEventListener('load', () => {
      if (app.getMetaData()) {
        setConfig((state) => ({ ...state, metaData: dwv.utils.objectToArray(app.getMetaData()) }));
        let selectedTool = 'Scroll';
        if (app.isMonoSliceData() && app.getImage().getNumberOfFrames() === 1) {
          selectedTool = 'ZoomAndPan';
        }
        onChangeTool(selectedTool);
        setConfig((state) => ({ ...state, dataLoaded: true }));
      }
    });

    app.addEventListener('loadend', () => {
      if (nReceivedError) {
        setConfig((state) => ({ ...state, loadProgress: 0 }));
        alert('Received errors during load. Check log for details.');
      }
      if (nReceivedAbort) {
        setConfig((state) => ({ ...state, loadProgress: 0 }));
        alert('Load was aborted.');
      }
    });

    app.addEventListener('loaditem', () => {
      nLoadItem += 1;
    });
    app.addEventListener('error', (event) => {
      console.log('error');
      console.error(event.error);
      nReceivedError += 1;
    });
    app.addEventListener('abort', () => {
      nReceivedAbort += 1;
    });
    app.addEventListener('keydown', (event) => app.defaultOnKeydown(event));
    window.addEventListener('resize', app.onResize);

    setConfig((state) => ({ ...state, dwvApp: app }));

    const buffer = base64ToArrayBuffer(file);
    const f = new File([new Blob([buffer])], `file.${mime === 'application/zip' ? 'zip' : 'dcm'}`, {
      type: mime,
    });
    app.loadFiles([f]);

    return () => {
      window.removeEventListener('resize', app.onResize);
      app.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = (tool) => {
    onChangeTool(tool);
  };

  return (
    <div className="dicomViewer" id={`dwv-${index}`}>
      <Progress percent={config.loadProgress} status={!config.dataLoaded && 'active'} />
      <div className="layerContainer">
        <canvas className="imageLayer">Only for HTML5 compatible browsers...</canvas>
        <div className="drawDiv" />
      </div>
      <DicomHeader tools={tools} config={config} onSelectChange={handleSelectChange} />
    </div>
  );
}

DicomViewer.propTypes = {
  m: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

export default DicomViewer;
