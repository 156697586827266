/* eslint-disable react/prop-types */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeDocument } from '../redux/documents/actions';
import { useFileTypes } from '../redux/metadata/selectors';
import { useRoles } from '../redux/user/selectors';
import { fetchAllFileTypes } from '../services/api';
import { isPatient } from '../services/rights';

const styles = { icon: { fontSize: 18, color: 'red', cursor: 'pointer' } };

function UserDocumentsList({ documents, isFetching, userId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useRoles();
  const fileTypes = useFileTypes('administrative');
  const columns = [
    {
      title: t('FRONT_DOCUMENT_TYPE'),
      dataIndex: 'file_type_id',
      render: (value) => fileTypes.find((f) => f.id === value)?.alias,
    },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (file) => (
        <Space space="large">
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer', {
                size: file.size,
                url: `/users/${userId}/files/${file.index}`,
              })
            }
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          {isPatient(roles) && (
            <DeleteOutlined
              onClick={() => dispatch(removeDocument(null, userId, file.index))}
              style={styles.icon}
            />
          )}
        </Space>
      ),
    },
  ];

  return <Table rowKey="id" dataSource={documents} columns={columns} loading={isFetching} />;
}

export default UserDocumentsList;
