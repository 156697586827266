import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import DocumentCreate from '../../components/documents/Create';
import DocumentsList from '../../components/documents/List';
import {
  createDocuments,
  fetchDocuments,
  removeDocument,
  isFetching as isDocumentFetching,
} from '../../redux/documents/actions';
import { useDocuments } from '../../redux/documents/selectors';
import Flex from '../../components/Flex';
import useStepActive from '../../hooks/useStepActive';
import { isLocalDoctor } from '../../services/rights';
import { useRoles } from '../../redux/user/selectors';
import { fetchStep } from '../../redux/step/actions';
import useHasPermission from '../../hooks/useHasPermission';

function MedicalFileDocuments({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [documents, isFetching] = useDocuments();
  const hasPermission = useHasPermission();
  const roles = useRoles();
  const { beforeStep, afterStep } = useStepActive();

  useEffect(() => {
    dispatch(fetchDocuments(medicalFile.reference));
  }, [medicalFile, dispatch]);

  const { reference } = medicalFile;

  return (
    <>
      {beforeStep('expertdoctor.stepdosmo') &&
        isLocalDoctor(roles) &&
        afterStep('localdoctor.stepaskmedicalfiles') && (
          <Flex>
            <Button
              type="primary"
              size="large"
              onClick={() => history.push(`/medicals/${reference}/ask`)}
            >
              {t('FRONT_BUTTON_TO_ASK_MEDCIAL_FILES')}
            </Button>
          </Flex>
        )}
      {beforeStep('expertdoctor.stepdosmo') && hasPermission('create health files') && (
        <>
          <DocumentCreate
            onSave={async (values) => {
              dispatch(isDocumentFetching());
              await createDocuments(medicalFile, null, values);
              dispatch(fetchStep(medicalFile.reference));
              dispatch(fetchDocuments(medicalFile?.reference));
            }}
            reference={reference}
          />
        </>
      )}
      <DocumentsList
        documents={documents}
        isFetching={isFetching}
        onRemove={(index) => dispatch(removeDocument(reference, null, index))}
      />
    </>
  );
}

MedicalFileDocuments.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MedicalFileDocuments;
